import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from '../../constant/useStyles';
import clsx from 'clsx';
import _ from 'lodash';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import slugs from "../../constant/slugs";
import { useQuery } from '@apollo/client';
import { GET_FEED_BACK_DETAIL } from '../../graphql/schemas/applicationQueries';
import Moment from 'react-moment';
FeedBackDetail.propTypes = {

};

function FeedBackDetail(props) {
    const { id } = useParams();
    const theme = useTheme();
    const { data, loading } = useQuery(GET_FEED_BACK_DETAIL, {
        variables: {
            id: id
        }
    });

    const [listMerge, setListMerge] = useState([]);

    useEffect(() => {
        if (data) {
            let result = _.map(data.feedback[0].media_list, (item) => {
                return {
                    url: process.env.REACT_APP_S3_GATEWAY + _.replace(_.get(item, 'media.url'), "mqdefault.jpg", "maxresdefault.jpg")
                }
            });
            setListMerge(result);
        }
    }, [data]);

    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const history = useHistory();
    if (loading || !data)
        return <CircularProgress />
    return (
        <Paper className={classes.root}>

            <Grid container className={classes.groupRow} style={{ display: matchesSM ? "block" : "flex" }} >
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={4} sm={4}>
                        <Typography component='div' className={classes.label}>Loại nền tảng:</Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <div>{_.get(data, 'feedback[0].applicationByApplication.name')}</div>
                    </Grid>
                </Grid>
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={2} sm={4}>
                        <Typography component='div' className={classes.label}>Loại góp ý:</Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <div>{_.get(data, 'feedback[0].feedback_type.name')}</div>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} style={{ display: matchesSM ? "block" : "flex" }} >
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={4} sm={4}>
                        <Typography component='div' className={classes.label}>Danh mục: </Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <div>{_.get(data, 'feedback[0].feedback_module.name')}</div>
                    </Grid>
                </Grid>
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={2} sm={4}>
                        <Typography component='div' className={classes.label}>Tính năng:</Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <div>{_.get(data, 'feedback[0].feedback_function.name')}</div>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} style={{ display: matchesSM ? "block" : "flex" }} >
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={3} sm={4}>
                        <Typography component='div' className={classes.label}>Người góp ý:</Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <div>{_.get(data, 'feedback[0].name')}</div>
                    </Grid>
                </Grid>
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={3} sm={4}>
                        <Typography component='div' className={classes.label}>Email/Số điện thoại:</Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <div>{_.get(data, 'feedback[0].phone')}</div>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} style={{ display: matchesSM ? "block" : "flex" }} >
                <Grid container xs={6} className={classes.groupRow} >
                    <Grid item={true} xs={3} sm={4}>
                        <Typography component='div' className={classes.label}>Ngày góp ý:</Typography>
                    </Grid>
                    <Grid item={true} xs={3} sm={6}>
                        <Moment format="DD/MM/YYYY HH:mm">{_.get(data, 'feedback[0].created')}</Moment>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={2}>
                    <Typography component='div' className={classes.label}>Hình ảnh:</Typography>
                </Grid>
                <Grid xs={8} item={true} className={clsx('grid-image', 'add-image-product', 'column-3')}>
                    {_.map(listMerge, (item, key) => {
                        const urlImage = _.get(item, 'url');//process.env.REACT_APP_S3_GATEWAY + _.get(item, 'url');
                        return (
                            <div key={key} className={classes.imgWrapper} >
                                {

                                    <img alt="Hình ảnh" src={urlImage} />

                                }
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={2}>
                    <Typography component='div' className={classes.label}>Mô tả:</Typography>
                </Grid>
                <Grid item={true} xs={3} sm={6}>
                    <div>{_.get(data, 'feedback[0].content')}</div>
                </Grid>
            </Grid>
            <div className={classes.backFooterStyle}>
                <Button
                    variant="contained"
                    onClick={() => history.push(slugs.feedback)}
                    startIcon={<ArrowBack />}
                >Quay lại</Button>
            </div>
        </Paper>
    );
}

export default FeedBackDetail;