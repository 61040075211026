import * as _ from 'lodash';
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { Paper, Divider, Typography, Button, Grid, ButtonGroup, Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


// import route
import { PersonAdd } from '@material-ui/icons';
import { headMenu, listFeedback, listFlatform, dataRes } from '../../constant/types';
import TableComponent from '../../components/TableComponent';
import { useStyles } from '../../constant/useStyles';
import { useQuery } from '@apollo/client';
import { GET_FEED_BACK_LIST } from '../../graphql/schemas/applicationQueries';
import { useCategory, useFeedbackType } from '../../graphql/hooks';
import { getNullableType } from 'graphql';



FeedBackList.propTypes = {

};

function FeedBackList(props) {

    const [dataRes, setDataRes] = useState([]);
    const [feedback, setFeedback] = useState();
    const [flatform, setFlatform] = useState();
    const feedbackType = useFeedbackType();
    const category = useCategory();
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [variables, setVariables] = useState({
        skip: skip,
        take: 10,
        order_by: {
            created: "desc"
        },
        where: {
            feedback_type: {
                code: {
                }
            },
            applicationByApplication: {
                application_category: {
                    code: {
                    }
                }
            }
        }
    })

    const { loading, data } = useQuery(GET_FEED_BACK_LIST, {
        variables: variables,
        fetchPolicy: 'cache-and-network',
    },
    );

    useEffect(() => {
        if (feedback) {
            setVariables({
                skip: skip,
                take: 10,
                order_by: {
                    created: "desc"
                },
                where: {
                    feedback_type: {
                        code: {
                            _eq: feedback
                        }
                    },
                    applicationByApplication: {
                        application_category: {
                            code: {
                            }
                        }
                    }
                }
            })
        }

        if (flatform) {
            setVariables({
                skip: skip,
                take: 10,
                order_by: {
                    created: "desc"
                },
                where: {
                    feedback_type: {
                        code: {
                        }
                    },
                    applicationByApplication: {
                        application_category: {
                            code: {
                                _eq: flatform
                            }
                        }
                    }
                }
            })
        }

        if (feedback && flatform) {
            setVariables({
                skip: skip,
                take: 10,
                order_by: {
                    created: "desc"
                },
                where: {
                    feedback_type: {
                        code: {
                            _eq: feedback
                        }
                    },
                    applicationByApplication: {
                        application_category: {
                            code: {
                                _eq: flatform
                            }
                        }
                    }
                }
            })
        }

        if (!feedback && !flatform) {
            setVariables({
                skip: skip,
                take: 10,
                order_by: {
                    created: "desc"
                },
                where: {
                    feedback_type: {
                        code: {
                        }
                    },
                    applicationByApplication: {
                        application_category: {
                            code: {
                            }
                        }
                    }
                }
            })
        }
    }, [feedback, flatform, skip])

    useEffect(() => {
        setPage(1);
        setSkip(0);
    }, [feedback, flatform])

    useEffect(() => {
        if (data) {
            let result = _.map(data.feedback, (item) => {
                return {
                    id: _.get(item, 'id'),
                    name: _.get(item, 'name'),
                    phone: _.get(item, 'phone'),
                    flatformType: _.get(item, 'applicationByApplication.name'),
                    feedbackType: _.get(item, 'feedback_type.name'),
                    time: _.get(item, 'created')
                }
            });
            setDataRes(result);
            setCount(_.get(data, 'feedback_aggregate.aggregate.count'))
        }
    }, [data]);

    const handleChangeFeedback = (e) => {
        setFeedback(e.target.value);
    }

    const handleChangeFlatform = (e) => {
        setFlatform(e.target.value);

    }
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <div>
                <Grid container direction="row">
                    <div style={{ width: '30%' }}>
                        <Typography className={classes.mgBottom}>Loại góp ý</Typography>
                        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                            <InputLabel htmlFor="filled-age-native-simple"></InputLabel>
                            <Select onChange={handleChangeFeedback} value={feedback}>
                                <MenuItem value={null}>-</MenuItem>
                                {_.map(feedbackType, (item, key) => (
                                    <MenuItem value={(_.get(item, 'code'))} key={_.get(item, 'code') ? _.get(item, 'code') : key}>{_.get(item, 'name')}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ width: '30%', marginLeft: 30 }}>
                        <Typography className={classes.mgBottom}>Nền tảng</Typography>
                        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                            <InputLabel htmlFor="filled-age-native-simple"></InputLabel>
                            <Select onChange={handleChangeFlatform} value={flatform}>
                                <MenuItem value={null}>-</MenuItem>
                                {_.map(category, (item, key) => (
                                    <MenuItem value={_.get(item, 'code')} key={_.get(item, 'code') ? _.get(item, 'code') : key}>{_.get(item, 'name')}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                    </div>
                </Grid>
            </div>
            <Divider style={{ margin: '30px 0' }} />

            <div>
                {!dataRes ? (<CircularProgress />) : (
                    <TableComponent
                        loading={loading}
                        data={dataRes}
                        headMenu={headMenu}
                        setSkip={(skip) => setSkip(skip)}
                        count={count}
                        page={page}
                        setPage={setPage}
                    />
                )}

            </div>

            <Divider style={{ margin: '30px 0' }} />
        </Paper >
    );
}

export default FeedBackList;