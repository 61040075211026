import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router';
import logo from "../../assets/icons/logo-ecoe-no-char.svg";
import * as _ from "lodash";
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { sendPasswordReset, signInWithEmailPassword } from '../../firebase/services/email';
import { user } from '../../constant/user';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sendBtn: {
        backgroundColor: "#00837b",
        color: "#ffff",
        '&:hover': {
            backgroundColor: "#028a82",
        },
    },
    cancelBtn: {
        verticalAlign: "baseline",
        background: "#e0e1e2 none",
        color: "rgba(0, 0, 0, .6)",
    },
    forgot: {
        cursor: "pointer",
        float: "right",
        marginTop: "5px",
        color: "grey",
    },
    submit: {
        margin: theme.spacing(3, 17, 2),
        backgroundColor: "#00837b"
    },

    alignCenter: {
        textAlign: "center",
        "& a": {
            color: "#00837b",
            fontWeight: "700"
        }
    },
    title: {
        marginTop: "10px",
        fontWeight: "700"
    },
    resetForm: {
        width: "700px",
        textAlign: "center"
    },
    titleForm: {
        textAlign: "center"
    },
    logoStyle: {
        width: "120px",
        height: "120px"
    },

}));

const ERROR_TYPE = (messageError) => _.get({
    'auth/user-not-found': 'Email người dùng chưa được đăng ký',
    'auth/invalid-email': 'Địa chỉ email không đúng.',
    'auth/wrong-password': 'Mật khẩu không đúng',
    'auth/too-many-requests': 'Quyền truy cập vào tài khoản này đã tạm thời bị vô hiệu hóa do nhiều lần đăng nhập không thành công. Bạn có thể khôi phục ngay lập tức bằng cách đặt lại mật khẩu của mình hoặc bạn có thể thử lại sau.'
}, messageError);

export default function LoginPage({ setAuth }) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const router = useHistory();
    const [showModal, setShowModal] = useState(false);

    /*
     *  Function to handle login by google account 
     */
    // const handleLoginByGoogle = () => {
    //     signInByGoogle().then(result => {
    //         console.log("Result: ", result);
    //         const credential = result.credential;
    //         const token = credential.accessToken;
    //         const user = result.user;
    //         router.push('/');
    //     });
    // 

    const handleLogin = (e) => {
        e.preventDefault();
        try {
            signInWithEmailPassword(email, password).then(data => {
                if (_.has(data, 'access_token')) {
                    // let user = {};
                    // _.set(user, 'userId', _.get(data, 'user.uid'));
                    // _.set(user, 'email', _.get(data, 'user.email'));
                    // localStorage.setItem('user', JSON.stringify(user));

                    user.setUserInfo(data);
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    setAuth(true)
                    router.push('/administrator/feedback');
                } else {
                    swal('Đăng nhập thất bại', ERROR_TYPE(data.code) || data.message, 'error');
                }
            });
        } catch (err) {
            swal('Đăng nhập thất bại', err, 'error');
        }
    };

    const handleResetPassword = async () => {
        try {
            sendPasswordReset(email).then((result) => {
                swal('Thành công', "Vui lòng kiểm tra email của bạn để đặt lại mật khẩu mới", 'success');
                setShowModal(false);
            }).catch((error) => {
                // var errorCode = error.code;
                // var errorMessage = error.message;
                swal('Thất bại', "Vui lòng kiểm lại địa chỉ email vừa nhập.", 'error');
            });
        } catch (error) {
            swal('Thất bại', "Vui lòng kiểm lại địa chỉ email vừa nhập.", 'error');
        }

    }

    return (
        <Container component="main" maxWidth="xs">
            {/* <CssBaseline /> */}
            <div className={classes.paper}>
                <img src={logo} className={classes.logoStyle} alt="ecoe admin" />
                <Typography component="h1" variant="h5" className={classes.title}>
                    Đăng nhập
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email đăng nhập"
                        name="email"
                        autoComplete="email"
                        type="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mật khẩu"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className={classes.forgot} onClick={() => setShowModal(true)}>Quên mật khẩu?</p>
                    <Button
                        type="submit"

                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLogin}
                    >
                        Đăng nhập
                    </Button>
                    <Dialog
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth='sm'
                        fullWidth={true}
                    >
                        <DialogTitle id="alert-dialog-title">Đặt lại mật khẩu</DialogTitle>
                        <DialogContent >
                            <DialogContentText className={classes.contentModal} id="alert-dialog-description">
                                <TextField id="outlined-basic"
                                    label="Email" variant="outlined"
                                    placeholder="Nhập địa chỉ email đăng ký"
                                    fullWidth
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleResetPassword} className={classes.sendBtn} variant="contained">
                                Gửi
                            </Button>
                            <Button onClick={() => setShowModal(false)} className={classes.cancelBtn} variant="contained">
                                Hủy
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </div>
        </Container>
    );
}