import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import clsx from 'clsx';
import axios from 'axios';
import { Fragment } from 'react';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
    imgWrapper: {
        position: 'relative',
        minHeight: '150px',
        borderRadius: '8px',
        overflow: 'hidden',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        '&.add-image-product': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#777',
            background: 'rgb(204 204 204 / 30%)',
            boxSizing: 'border-box',
            cursor: 'pointer',
            '& > div': {
                marginTop: '15px',
            },
            '&:hover': {
                border: '1px solid #777',
                background: 'rgb(204 204 204 / 100%)',
            },
            '& > svg': {
                boxSizing: 'border-box',
                cursor: 'pointer',
            }
        },
        '&.loading': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > div': {
                display: 'block',
                height: '50px',
                width: '50px',
            },
        },
    },

    uploadButton: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        border: '1px solid #0A2240',
        borderRadius: '12px',
        padding: '15px',
        marginTop: '30px',
        background: '#fff',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.5',
        },
    },
    deleteImage: {
        fill: '#000',
        background: 'rgb(255 255 255 / 50%)',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            fill: '#566F90',
            background: 'rgb(255 255 255 / 100%)',
        },
    },
    inputStyle: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        cursor: 'pointer',
    },
}));

const ImagePicker = ({ data, setData, type, limit, numberColumn, buttonType }) => {
    let isOver = false;
    const classes = useStyles();
    const theme = useTheme();
    let listMerge = data;
    const [progress, setProgress] = useState(false);

    const FileSelectedHandler = (e) => {
        setProgress(true);
        const remainFile = limit - _.size(listMerge);
        const files = _.get(e, 'target.files');
        let tempUploadFiles = [];
        let promiseList = [];

        _.forEach(files, item => {
            if (item.size > 5242880)
                isOver = true
        })
        if (isOver) {
            swal({
                title: "Kích thước file vượt quá 5MB",
                icon: "warning",
                dangerMode: true,
            })
            e.target.value = '';
        }

        _.each(_.take(files, remainFile), file => {
            let fd = new FormData();
            fd.append('file', file)
            fd.append('media_type_code', type);
            let promise = axios.post(process.env.REACT_APP_BASEURL_UPLOAD, fd);
            promiseList.push(promise);
        });

        Promise.all(promiseList).then((values) => {
            _.forEach(values, function (value) {
                tempUploadFiles = [...tempUploadFiles, { id: _.get(value, 'data[0].id'), url: _.get(value, 'data[0].url') }]
            });
            setData([...listMerge, ...tempUploadFiles]);
        });

        e.target.value = '';
    };

    const FileDeleteHandler = (item) => {
        let listTemp = listMerge;
        _.remove(listTemp, { 'id': _.get(item, 'id') });
        setData([...listTemp]);
    };

    useEffect(() => {
        setProgress(false);
    }, [data]);

    return (
        <Fragment>
            <Grid xs={8} item={true} className={clsx('grid-image', 'add-image-product', numberColumn === 3 && `column-3`, numberColumn === 4 && `column-4`)}>
                {_.map(listMerge, (item, key) => {
                    const urlImage = process.env.REACT_APP_S3_GATEWAY + _.get(item, 'url');
                    return (
                        <div key={key} className={classes.imgWrapper} >
                            {
                                ((_.get(item, 'url').slice(-4) === '.pdf') && (type === 'DOCUMENT')) ? (
                                    <embed width="100%" height="100%" name="plugin" src={urlImage} type="application/pdf"></embed>
                                ) : (
                                    <img alt="Hình ảnh" src={urlImage} />
                                )
                            }
                            <DeleteForeverIcon
                                fontSize='large'
                                className={classes.deleteImage}
                                onClick={() => FileDeleteHandler(item)}
                            />
                        </div>
                    )
                })}

                {progress && (
                    <div className={clsx(classes.imgWrapper, 'loading')} >
                        <CircularProgress />
                    </div>
                )}

                {(_.size(listMerge) < limit && _.upperCase(buttonType) === 'CARD') && (
                    <div className={clsx(classes.imgWrapper, 'add-image-product')}>
                        <input
                            type='file'
                            accept="image/*"
                            multiple
                            className={classes.inputStyle}
                            onChange={FileSelectedHandler}
                        />
                        <AddCircleIcon fontSize='large' />
                        <div>Hình ảnh chính</div>
                    </div>
                )}
            </Grid>

            {_.upperCase(buttonType) === 'DEFAULT' && (
                <Grid xs={8} item={true}>
                    <Typography
                        className={classes.uploadButton}
                        disabled={_.size(data) >= 10}
                        component='div'
                    >
                        <input
                            type='file'
                            accept="image/*"
                            multiple
                            className={classes.inputStyle}
                            onChange={FileSelectedHandler}
                        />
                        <span>Tải thêm ảnh</span>
                    </Typography>

                </Grid>
            )}
        </Fragment>
    );
}

ImagePicker.propTypes = {
    type: PropTypes.oneOf(['PHOTO', 'PHOTO360', 'VIDEO', '3DVIEW', 'STREETVIEW', 'BANNER', 'PROJECT', 'DOCUMENT', 'AVATAR', 'ICON']).isRequired,
    limit: PropTypes.number,
    data: PropTypes.array,
    numberColumn: PropTypes.number,
    buttonType: PropTypes.oneOf(['DEFAULT', 'CARD']),
};
ImagePicker.defaultProps = {
    type: 'PHOTO',
    limit: 10,
    data: [],
    numberColumn: 3,
    buttonType: 'CARD',
};
export default ImagePicker;
