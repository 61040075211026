// Table
import * as _ from 'lodash';
import React, { Fragment, useState, useEffect } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    Toolbar, Typography, IconButton, Tooltip, CircularProgress,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 80px)',
        padding: '0 30px',
        position: 'relative',
    },
    headerStyle: {
        background: '#F5F5F5',
        '& th > span': {
            fontWeight: 'bold',
            color: '#000',
        },
        '& th > .MuiTableSortLabel-root.MuiTableSortLabel-active': {
            color: '#000',
            '& .MuiTableSortLabel-icon': {
                color: '#000 !important',
            }

        },
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    rowStyle: {
        position: 'relative',
        zIndex: 5,
    },
    checkboxStyle: {
        background: 'green',
        position: 'relative',
        zIndex: 9,
    },
    nodataStyle: {
        color: "#E27F35",
        fontSize: '16px',
        fontWeight: 'bold'
    },
}));

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
};

const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.headerStyle}>
            <TableRow>
                {_.map(headCells, (item, key) =>
                    <TableCell
                        key={key}
                        align="left"
                        padding={item.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === item.id ? order : false}
                    >
                        {item.label}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">

                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const TableComponent = ({
    page,
    setPage,
    data,
    typeList,
    count,
    headMenu = [],
    numRowsPerPage = 10,
    loading,
    setSkip }) => {
    let headCells = [];
    if (_.isEmpty(headMenu) && !_.isEmpty(data)) {
        headCells = _.map(_.keys(_.head(data)), (value, key) => {
            return ({ id: value, numeric: false, disablePadding: false, label: value })
        });
    } else {
        headCells = headMenu
    }

    const orderDefault = _.get(_.head(headCells), 'id');
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(orderDefault);
    const [selected, setSelected] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(numRowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = _.map(data, (item) => _.get(item, orderDefault));
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setSkip((newPage * 10) - 10);
    };

    const isSelected = (code) => selected.indexOf(code) !== -1;


    if (loading) return <CircularProgress />;

    return (
        <Fragment>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size='medium'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={count}
                        headCells={headCells}
                    />
                    <TableBody>
                        {data.length > 0 ? <Fragment>
                            {data.map((row, index) => {
                                const isItemSelected = isSelected(_.get(row, orderDefault));
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        component={Link}
                                        to={`/administrator/feedback/detail/${_.get(row, 'id')}`}
                                        hover
                                        style={{ textDecoration: 'none' }}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={_.get(row, 'id')}
                                        selected={isItemSelected}
                                    >
                                        {_.map(headCells, (value, key) => <TableCell align="left" key={key}>{
                                            value.id === 'time' ?
                                                <Moment format="DD/MM/YYYY HH:mm">{_.get(row, 'time')}</Moment>
                                                : _.get(row, value.id)
                                        }</TableCell>)}
                                    </TableRow>
                                );
                            })
                            }
                        </Fragment> :
                            (
                                <TableRow>
                                    <TableCell align="center" colSpan={12}>
                                        <Typography className={classes.nodataStyle}>
                                            Không có dữ liệu thích hợp
                                        </Typography>
                                    </TableCell>
                                </TableRow>


                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Number(Math.ceil(count / 10))}
                onChange={handleChangePage}
                page={page}
                variant="outlined"
                shape="rounded"
                style={{ margin: '16px 16px', paddingLeft: "35%" }}
                showLastButton
                showFirstButton
                defaultPage={1}
            /><br />
        </Fragment>
    )
};
export default TableComponent;
