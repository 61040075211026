import React, { useEffect } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import MuiListItem from "@material-ui/core/ListItem";
import {
    ExitToApp as ExitToAppIcon
}
    from '@material-ui/icons';
import swal from "sweetalert";
import { user } from '../../constant/user';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import LanguageIcon from '@material-ui/icons/Language';
import * as _ from "lodash";


const DrawerCustomer = ({ setAuth, role }) => {
    const history = useHistory();
    const location = useLocation();
    const useStyles = makeStyles((theme) => ({
        icon: {
            color: "#fff"
        },
        border: {
            borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
            padding: "15px 30px",
            '& .MuiListItemText-root': {
                '& > .MuiTypography-body1': {
                    color: '#ffffff'
                }
            }
        },
        backgroundColor: "#32373d",
    }));

    const ListItem = withStyles({
        root: {
            "&$selected": {
                backgroundColor: "red",
                color: "white",
                "& .MuiListItemIcon-root": {
                    color: "white"
                }
            },
            "&$selected:hover": {
                backgroundColor: "purple",
                color: "white",
                "& .MuiListItemIcon-root": {
                    color: "white"
                }
            },
            "&:hover": {
                backgroundColor: "#525252",
                color: "white",
                "& .MuiListItemIcon-root": {
                    color: "white"
                }
            }
        },
        selected: {}
    })(MuiListItem);


    const classes = useStyles();
    useEffect(() => {
        // console.log(location);
    }, [location])
    const itemsList = _.includes(_.toUpper(user.getValue("role")), "ADMIN") ? [{
        text: "Đăng xuất",
        icon: <ExitToAppIcon className={classes.icon} />,
        onClick: () => {
            swal({
                title: "Bạn có chắc muốn đăng xuất?",
                icon: "warning",
                buttons: ["Hủy", "Đăng xuất"],
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setAuth(false);
                        user.reset()
                        history.push("/");
                        window.location.reload();
                    } else {
                    }
                });
        }
    }] : [

        {
            text: "ECOE Client App",
            icon: <PhoneIphoneIcon className={classes.icon} />,
            onClick: () => {
                history.push("/client-app")
            },
            style: matchPath(location.pathname, { path: '/client-app' }) ? { backgroundColor: '#00837b' } : undefined
        },
        {
            text: "ECOE Agent App",
            icon: <PhoneAndroidIcon className={classes.icon} />,
            onClick: () => {
                history.push("/agent-app")
            },
            style: matchPath(location.pathname, { path: '/agent-app' }) ?
                { backgroundColor: '#00837b' } :
                undefined
        },
        {
            text: "ECOE Administrator Web",
            icon: <LanguageIcon className={classes.icon} />,
            onClick: () => {
                history.push("/web-admin")
            },
            style:
                matchPath(location.pathname, { path: '/web-admin' }) ?
                    { backgroundColor: '#00837b' } :
                    undefined
        },
        // {
        //     text: "Đăng xuất",
        //     icon: <ExitToAppIcon className={classes.icon} />,
        //     onClick: () => {
        //         swal({
        //             title: "Bạn có chắc muốn đăng xuất?",
        //             icon: "warning",
        //             buttons: ["Hủy", "Đăng xuất"],
        //             dangerMode: true,
        //         })
        //             .then((willDelete) => {
        //                 if (willDelete) {
        //                     setAuth(false);
        //                     user.reset()
        //                     history.push("/login");
        //                 } else {
        //                 }
        //             });
        //     }
        // }
    ];
    return (
        <List className={classes.bg}>
            {itemsList.map((item, index) => {
                const { text, icon, onClick } = item;
                return (
                    <ListItem style={item.style} button key={text} onClick={onClick} className={classes.border}>
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                        <ListItemText primary={text} color="white" />
                    </ListItem>
                )
            }

            )}
        </List>
    )
}

export default DrawerCustomer
