const slugs = {
    dashboard: '/',
    signIn: '/administrator/login',
    clientApp: "/client-app",
    agentApp: "/agent-app",
    webAdmin: "/web-admin",
    admin: "/administrator",
    feedback: "/administrator/feedback",
    feedbackDetail: "/administrator/feedback/detail/:id",
    notFound: "/404-not-found"
}

export default slugs;