import * as _ from 'lodash';
import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_APPLICATION_BY_CATEGORY, GET_FEED_BACK_CATEGORY, GET_FEED_BACK_FUNCTIONS, GET_FEED_BACK_MODULES_BY_CODE, GET_FEED_BACK_TYPE } from './schemas/applicationQueries';
import { INSERT_FEED_BACK } from './schemas/applicationMutations';
import swal from 'sweetalert';


export const useApplicationBy = (category) => {
    const { data } = useQuery(GET_APPLICATION_BY_CATEGORY, {
        variables: {
            code: category
        }
    });
    return _.get(data, 'application');
}


export const useFeedbackType = () => {
    const { data } = useQuery(GET_FEED_BACK_TYPE, {});
    return _.get(data, 'feedback_type');
}


export const useCategory = () => {
    const { data } = useQuery(GET_FEED_BACK_CATEGORY, {});
    return _.get(data, 'application_category');
}

export const useCreateFeedback = () => {
    const [createFeedback] = useMutation(INSERT_FEED_BACK, {
        onCompleted: async () => {
            return await swal({
                title: "Gửi đánh giá thành công",
                icon: "success"
            })
        },
        onError: async (err) => {
            return await swal({
                title: `${err.message}`,
                icon: "error",
            });
        }
    });

    return {
        createFeedback
    };
}

export const useModuleType = (type) => {
    const { data } = useQuery(GET_FEED_BACK_MODULES_BY_CODE, {
        variables: {
            cateCode: type
        }
    });
    return _.get(data, 'feedback_module');
};
