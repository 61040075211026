export const typeSelect = [
    {
        id: 1,
        name: "Lỗi (bug) khi sử dụng"
    },
    {
        id: 2,
        name: "Góp ý để tăng trải nghiệm người dùng hoặc bổ sung tính năng (enhancement và new functions)"
    },
    {
        id: 3,
        name: "Góp ý về ECOE"
    }
];

export const headMenu = [
    { id: 'id', numeric: false, disablePadding: false, label: 'ID góp ý' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Họ và tên người góp ý' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Email/Số điện thoại' },
    // { id: 'accountType', numeric: false, disablePadding: false, label: 'Loại tài khoản' },
    { id: 'flatformType', numeric: false, disablePadding: false, label: 'Loại nền tảng' },
    { id: 'feedbackType', numeric: false, disablePadding: false, label: 'Loại góp ý' },
    { id: 'time', numeric: false, disablePadding: false, label: 'Thời gian góp ý' },
];


export const listFeedback = [
    {
        id: 1,
        name: "Bug"
    },
    {
        id: 2,
        name: "Enhancement"
    },
    {
        id: 3,
        name: "Góp ý khác"
    }
];

export const listFlatform = [
    {
        id: 1,
        name: "Agent App"
    },
    {
        id: 2,
        name: "Client App"
    },
    {
        id: 3,
        name: "Web Admin"
    }
];


export const dataRes = [
    {
        id: "F001",
        name: "Trần Thanh Long",
        code: "E00020",
        accountType: "Admin",
        flatformType: "Web Admin",
        feedbackType: "Bug",
        time: "new Date()"
    },
    {
        id: "F002",
        name: "Cao Xuân Tùng",
        code: "C0002",
        accountType: "Khách hàng",
        flatformType: "Client App",
        feedbackType: "Enhancement",
        time: "new Date()"
    },
    {
        id: "F005",
        name: "Lưu Văn Trí",
        code: "E005",
        accountType: "Agent",
        flatformType: "Agent App",
        feedbackType: "Góp ý khác",
        time: "new Date()"
    },
    {
        id: "F006",
        name: "Lò Vi Sóng",
        code: "C0007",
        accountType: "Khách hàng",
        flatformType: "Client App",
        feedbackType: "Bug",
        time: "new Date()"
    },
    {
        id: "F007",
        name: "Lý Văn Thông",
        code: "E008",
        accountType: "Nhân viên",
        flatformType: "Web Admin",
        feedbackType: "Góp ý khác",
        time: "new Date()"
    }
]