import { ApolloClient, InMemoryCache, fromPromise, ApolloLink } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import * as _ from "lodash";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { refreshToken } from "../../firebase/services/token";
export const ssrMode = typeof window === 'undefined';

const url = process.env.REACT_APP_HASURA_ENPOINT;

const getAuthorization = () => {
    let access_token = localStorage.getItem('access_token');
    if (access_token) {
        return {
            'authorization': `Bearer ${access_token}`
        }
    }

    return {};
};


const authLink = setContext(async (_, { headers }) => {
    return {
        headers: {
            // 'x-hasura-admin-secret': process.env['REACT_APP_X_HASURA_ADMIN_SECRET'],
            ...getAuthorization(),
            ...headers, /** Please don't edit the order */
        }
    }
});

const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    const { cache } = operation.getContext();
    /** Handle reset token */
    try {
        _.map(graphQLErrors, ({ message, extensions }) => {
            if (extensions.code === 'invalid-jwt') {
                refreshToken();
            }
            console.log("[Graphql error]:", message);
        });
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    } catch (error) {
        console.log("Error Link: ", error);
    }
});
export const client = new ApolloClient({
    // uri: process.env.REACT_APP_HASURA_ENPOINT,
    // headers: {
    //     'x-hasura-admin-secret': process.env['REACT_APP_X_HASURA_ADMIN_SECRET']
    // },
    ssrMode,
    // link: ApolloLink.from([authLink, errorLink, createUploadLink({ uri: url, credentials: 'same-origin' })]),
    link: ApolloLink.from([authLink, errorLink, createUploadLink({ uri: url, credentials: 'same-origin' })]),
    cache: new InMemoryCache()
});
