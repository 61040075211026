import * as _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    MenuItem, FormControl, Select, InputLabel, NativeSelect
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        '&:not(.fullwidth)': {
            width: 250,
        },
        '&.fullwidth': {
            width: '93%',
        },
    },
    empty: {
        color: "grey"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultPadding: {
        '&>div': {
            padding: '10.5px 14px',
        },
    },
    upperCase: {
        textTransform: "uppercase",
    },
    selectUpperCase: {
        textTransform: "uppercase",
        '&>div': {
            padding: '10.5px 14px',
        },
    }

}));

const InputDropdown = ({ description, onChange, fullwidth, data, isFlatform, value }) => {

    const [selectValue, setSelectValue] = useState("");
    const classes = useStyles();
    const handleChange = (e) => {
        e.preventDefault();
        setSelectValue(e.target.value);
        onChange(e.target.value)
    };

    useEffect(() => setSelectValue(value ?? ''), [value]);

    return (
        <Fragment>
            <FormControl variant="outlined" className={clsx(classes.formControl, { 'fullwidth': fullwidth })}>
                <InputLabel htmlFor="age-native-helper"></InputLabel>
                <Select
                    className={isFlatform ? classes.selectUpperCase : classes.defaultPadding}
                    id={`input-dropdown-${isFlatform}`}
                    value={selectValue}
                    onChange={handleChange}
                    displayEmpty={true}
                >
                    <MenuItem value="" className={classes.empty} >{description}</MenuItem>
                    {
                        _.map(data, (item, key) => {
                            return <MenuItem key={key} value={isFlatform ? item : _.get(item, 'id')} className={isFlatform ? classes.upperCase : undefined}>{_.get(item, 'name')}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Fragment >
    );
};

InputDropdown.propTypes = {
    fieldname: PropTypes.string,
    object: PropTypes.object,
    setObject: PropTypes.func,
    discription: PropTypes.string,
    data: PropTypes.array,
};

export default InputDropdown;
