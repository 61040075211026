import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import notFoundImage from '../../assets/images/notfound.jpg'

const useStyles = makeStyles({
    page_404: {
        padding: '40px 0',
        background: '#fff',
        fontFamily: 'Arvo, serif',
        display: 'flex',
        justifyContent: 'center'
    },
    four_zero_four_bg: {
        height: '400px',
        backgroundPosition: 'center',
        backgroundImage: `url(${notFoundImage})`,
        backgroundRepeat: 'no-repeat',
        '& h1': {
            fontSize: 60,
            textAlign: 'center'
        },
        '& h3': {
            fontSize: 60
        }
    },
    link_404: {
        color: '#fff',
        padding: '10px 20px',
        background: "#07807d",
        margin: '20px 0',
        display: 'inline-block',
        '& img': {
            width: '100%'
        },
        textDecoration: 'none'
    },
    contant_box_404: {
        '& h3': {
            fontSize: '40px'
        },
        '& p': {
            fontSize: '20px'
        }

    }
})

const NotFoundPage = () => {
    const classes = useStyles();
    return (
        <section className={classes.page_404}>
            <div>
                <div>
                    <div>
                        <div style={{ textAlign: 'center' }}>
                            <div className={classes.four_zero_four_bg}></div>
                            <div className={classes.contant_box_404}>
                                <h3>
                                    Trang không tồn tại
                                </h3>
                                <p>Chúng tôi xin lỗi trang bạn tìm không tồn tại</p>
                                <Link to="/" className={classes.link_404}>Về trang chủ</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotFoundPage