import { gql } from "@apollo/client";

export const GET_APPLICATION_BY_CATEGORY = gql`
query getApplicationByCategory($code: String!) {
    application(where:{application_category:{code:{_eq:$code}}}){
      id
      name
      current_version
      application_type{
        id
        code
        name
      }
    }
  }
  
`

export const GET_FEED_BACK_TYPE = gql`
query getFeedbackType{
    feedback_type{
      id
      code
      name
    }
  }
  
`

export const GET_FEED_BACK_DETAIL = gql`
query getFeedbackList($id: Int!) {

  feedback(where: {id:{_eq:$id}}) {
    id
    content
    name
    phone
    account: accountByAccount{
      id
      code
    }
    applicationByApplication{
      id
      name
      application_category{
        id
        code
        name
      }
      application_type{
        id
        code
        name
      }
    }
    versionByVersion{
      id
      name
    }
    feedback_type{
      id
      code
      name
    }
    feedback_module {
      id
      name
    }
    feedback_function {
        id
        name
    }
    created
    media_list: feedback_media {
      media: medium {
        id
        name
        url
        type: media_type{
          id
          code
          name
        }
      }
    }
  }
}

`

export const GET_FEED_BACK_CATEGORY = gql`
query getApplicationCategory {
    application_category{
      id
      code
      name
    }
  }
  
`

export const GET_FEED_BACK_LIST = gql`
query getFeedbackList($skip: Int!, $take: Int!, $order_by: [feedback_order_by!] = {}, $where: feedback_bool_exp = {}) {
    feedback_aggregate(where:$where){
      aggregate{count}
    }
    feedback(offset: $skip, limit: $take, order_by: $order_by, where: $where) {
      id
      phone
      name
      account: accountByAccount{
        id
        code
        full_name
      }
      applicationByApplication{
        name
        application_category{
          name
        }
      }
      versionByVersion{
        name
      }
      feedback_type{
        name
      }
      created
    }
  }
  
`

export const GET_FEED_BACK_MODULES_BY_CODE = gql`
query GetModulesByCateCode($cateCode: String!) {
  feedback_module(where: {application_category:{code:{_eq:$cateCode}}}) {
    id
    name
  }
}
`

export const GET_FEED_BACK_FUNCTIONS = gql`
query GetFeedbackFunctions($moduleId: Int!) {
  feedback_function(where: {module: {_eq: $moduleId}}) {
    id
    name
  }
}
`