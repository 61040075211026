import React, { useState, useEffect, Fragment } from "react";
import LoginPage from "./pages/SignIn";
import {
  BrowserRouter as Router, Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import logo from "./assets/images/feedback.png";
// import Drawer from "./Drawer";
import Drawer from "@material-ui/core/Drawer";
import { createTheme, makeStyles, ThemeProvider, useTheme } from "@material-ui/core/styles";

import { AppBar } from "@material-ui/core";
import clsx from 'clsx';
import * as _ from "lodash";

// import Drawer
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DrawerUI from "./components/drawer/DrawerCustomer";
import { client } from "./graphql/config";

//import icons
import slugs from "./constant/slugs";
import AgentApp from "./pages/agentApp";
import ClientApp from "./pages/clientApp";
import WebAdmin from "./pages/webAdmin";
import NotFoundPage from "./pages/other";
import { user } from "./constant/user";
import FeedBackList from "./pages/feedback/FeedBackList";
import FeedBackDetail from "./pages/feedback/FeedBackDetail";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "30px",
    overflowY: 'scroll'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#32373d",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#32373d'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: '64px',
    width: 'calc(100vw - 279px)',
    '&.fullwidth': {
      width: '100vw',
    },
    overflow: 'hidden',

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logoStyle: {
    width: "230px",
    height: "120px"
  },
}));

const App = () => {
  const isAdmin = _.includes(_.toUpper(user.getValue("role")), "ADMIN");

  const [auth, setAuth] = useState(false);
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const baseTheme = createTheme();
  const classes = useStyles();

  const PrivateRoute = ({ component, path, exact, render = undefined }) => {

    const condition = localStorage.getItem('access_token');

    return condition ? (<Route path={path} exact={exact} component={component} render={render} />) :
      (<Redirect to="/login" />);
  };

  useEffect(() => {
    if (window.localStorage.getItem('refresh_token')) {
      setAuth(true);
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={baseTheme}>
        <Router>
          <div style={{ display: 'flex' }}>
            {/* {auth && ( */}
            <Fragment>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>

              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <Fragment> <img src={logo} className={classes.logoStyle}></img><ChevronLeftIcon style={{ color: '#ffffff' }} /> </Fragment> : <ChevronRightIcon style={{ color: '#ffffff' }} />}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <DrawerUI setAuth={setAuth} role={user.getValue("role")} />
                </List>

              </Drawer>
            </Fragment>

            {/* )} */}


            <div className={clsx(classes.content, !open && 'fullwidth', { [classes.contentShift]: open, })}>

              <Switch>
                <Route exact path={slugs.signIn} render={() => <LoginPage setAuth={setAuth} />} />
                <Route exact path={slugs.dashboard} component={ClientApp} />
                <Route exact path={slugs.clientApp} component={ClientApp} />
                <Route exact path={slugs.agentApp} component={AgentApp} />
                <Route exact path={slugs.webAdmin} component={WebAdmin} />
                {!isAdmin && <Route exact path={slugs.admin} render={() => <LoginPage setAuth={setAuth} />} />}
                {isAdmin && <PrivateRoute exact path={slugs.feedback} component={FeedBackList} />}
                {isAdmin && <PrivateRoute exact path={slugs.admin} component={FeedBackList} />}
                {isAdmin && <PrivateRoute exact path={slugs.feedbackDetail} component={FeedBackDetail} />}
                {isAdmin && <PrivateRoute exact path={slugs.dashboard} component={FeedBackList} />}
                <Route exact path={slugs.notFound} component={NotFoundPage} />
                <Redirect from='*' to={slugs.notFound} />
              </Switch>
            </div>

          </div>
        </Router>
      </ThemeProvider>
    </ApolloProvider >
  );
}
export default App;

