import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Typography, TextField, Input } from '@material-ui/core';
import InputDropdown from './InputDropdown';
import * as _ from "lodash";
import { useStyles } from '../constant/useStyles';
import clsx from 'clsx';
import ImagePicker from './ImagePicker';
import { Telegram } from '@material-ui/icons';
import { useApplicationBy, useCreateFeedback, useFeedbackType, useFunctionType, useModuleType } from '../graphql/hooks';
import { useHistory } from 'react-router-dom';
import { GET_FEED_BACK_FUNCTIONS } from '../graphql/schemas/applicationQueries';
import { useLazyQuery } from '@apollo/client';
import InputDropdownWeb from './InputDropdownWeb';

CommonPage.propTypes = {

};

function CommonPage({ pageType }) {
    const classes = useStyles();
    const [type, setType] = useState();
    const [flatform, setFlatform] = useState([]);
    const [module, setModule] = useState();
    const [functionId, setFunctionId] = useState();
    const [content, setContent] = useState("");
    const [userInfo, setUserInfo] = useState({ name: "", info: "" });

    const [getFunctions, { loading: loadingFunc, data: dataFunc }] = useLazyQuery(GET_FEED_BACK_FUNCTIONS);

    //appType - (version and flatform)
    const [appType, setAppType] = useState();
    const [uploadImages, setUploadImages] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const application = useApplicationBy(pageType);
    const moduleType = useModuleType(pageType);
    const feedbackType = useFeedbackType();
    const history = useHistory();
    const { createFeedback } = useCreateFeedback();

    useEffect(() => {
        if (application) {
            let result = _.map(application, (item) => {
                return {
                    id: _.get(item, 'id'),
                    current_version: _.get(item, 'current_version'),
                    name: _.get(item, 'application_type.name')
                }
            });
            if (pageType === 'WEB') {
                setAppType(result[0]);
            }
            setFlatform(result);
        }
    }, [application]);

    useEffect(() => {
        if (module) {
            getFunctions({
                variables: {
                    moduleId: module
                }
            });
        }
    }, [module]);

    useEffect(() => {
        if (type && !_.isEmpty(uploadImages) && appType && module && functionId && userInfo.name) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [type, uploadImages, appType]);

    const handleFeedback = () => {
        //Call API
        let media_list = _.map(uploadImages, (item) => {
            return item.id;
        });
        createFeedback({
            variables: {
                data: {
                    application: _.get(appType, 'id'),
                    version: _.get(appType, 'current_version'),
                    name: _.get(userInfo, 'name'),
                    phone: _.get(userInfo, 'info'),
                    type: type,
                    module: module,
                    function: functionId,
                    media_list: media_list,
                    content: content
                }
            }
        }).then(() => {
            setUploadImages([]);
            setAppType(null);
            setType(null);
            setModule(null);
            setFunctionId(null);
            setContent("");
        })
    }

    const convertTitleByType = (type) => {
        let result = "";
        switch (type) {
            case 'AGENT':
                result = 'Agent App'
                break;
            case 'CLIENT':
                result = 'Client App'
                break;
            case 'WEB':
                result = 'Administrator Website'
                break;

            default:
                break;
        }
        return result;
    };
    return (
        <div>
            <h1>{convertTitleByType(pageType)}</h1>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Họ và tên:</Typography>
                </Grid>
                <Grid item={true} className={classes.info}>
                    <TextField variant="outlined" placeholder="Nhập họ và tên" value={userInfo.name} fullWidth onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })} />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label)}>Thông tin liên hệ:</Typography>
                </Grid>
                <Grid item={true} className={classes.info}>
                    <TextField variant="outlined" placeholder="Nhập Email/Số điện thoại" value={userInfo.info} fullWidth onChange={(e) => setUserInfo({ ...userInfo, info: e.target.value })} />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Chọn nền tảng:</Typography>
                </Grid>
                <Grid item={true} xs={5}>
                    {pageType === 'WEB' ?
                        <InputDropdownWeb
                            fieldname='flatform'
                            description=''
                            fullwidth
                            data={flatform}
                            onChange={(value) => setAppType(value)}
                            isFlatform
                            defaultValue={appType}
                            value={appType}
                        />

                        : <InputDropdown
                            fieldname='flatform'
                            description=''
                            fullwidth
                            data={flatform}
                            onChange={(value) => setAppType(value)}
                            isFlatform
                            value={appType}
                        />}

                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Bạn muốn đánh giá và góp ý:</Typography>
                </Grid>
                <Grid item={true} xs={5}>
                    <InputDropdown
                        fieldname='appType'
                        description=''
                        fullwidth
                        value={appType}
                        data={feedbackType}
                        onChange={(value) => setType(value)}
                    />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Chọn danh mục:</Typography>
                </Grid>
                <Grid item={true} xs={5}>
                    <InputDropdown
                        fieldname='module'
                        description=''
                        fullwidth
                        value={module}
                        data={moduleType}
                        onChange={(value) => setModule(value)}
                    />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Chọn tính năng:</Typography>
                </Grid>
                <Grid item={true} xs={5}>
                    <InputDropdown
                        fieldname='function'
                        fullwidth
                        value={functionId}
                        data={_.get(dataFunc, 'feedback_function')}
                        onChange={(value) => setFunctionId(value)}
                    />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label, 'required')}>Hình ảnh/File minh họa:</Typography>
                    <Typography component='div' className={clsx(classes.label, 'description')}>Tối đa 5 hình, dung lượng 5MB/hình</Typography>
                </Grid>
                <Grid item={true} xs={7}>
                    <ImagePicker
                        data={uploadImages}
                        setData={setUploadImages}
                        numberColumn={4}
                        buttonType='CARD'
                        type="PHOTO"
                        limit={5}
                    />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow} >
                <Grid item={true} xs={3}>
                    <Typography component='div' className={clsx(classes.label)}>Mô tả:</Typography>
                </Grid>
                <Grid item={true} style={{ width: "39%" }}>
                    <TextField
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        placeholder="Nhập mô tả tối đa 800 ký tự"
                        variant="outlined"
                        inputProps={{
                            maxLength: 800,
                        }}
                    />
                </Grid>
            </Grid>
            <div className={classes.marginDivider}></div>
            <Grid container className={classes.groupRow}>
                <Grid item={true} xs={3}>
                </Grid>
                <Grid item={true} xs={5} className={classes.alignCenter}>
                    <Button
                        variant="contained"
                        className={classes.sendBtn}
                        onClick={handleFeedback}
                        disabled={disabled}
                        startIcon={<Telegram />}
                    >Gửi đánh giá</Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default CommonPage;